import services from "./index";
import store from "@/store";
import qs from "querystring";

export default {
    impersonationProfileId: null,
    getConfig() {
        //console.log('TOKEN', store.getters.jwtToken);
        return {
            headers: {
                //authorization: `Bearer ${store.getters.jwtToken}`,
                "x-impersonation-profile-id": this.impersonationProfileId || '',
            }
        };
    },
    all(limit) {
        return services.http.get(`/v1.0/activity/?limit=${limit || 25}`, this.getConfig());
    },
    allPaged(limit, continuationToken) {
      return services.http.post(`/v1.0/activity/`,qs.stringify({limit: limit || 25, continuationToken:continuationToken}), this.getConfig());
    },
    get(id) {
        return services.http.get("/v1.0/activity/"+id, this.getConfig());
    },
    getQuickEntry(profileId, id) {
        return services.http.get(`/v1.0/activity/public/${profileId}/${id}`);
    },
    getActivityTypes() {
        return services.http.get("/v1.0/activity/activity_types", this.getConfig());
    },
    getActivityEntryContext() {
        return services.http.get("/v1.0/activity/entry-context", this.getConfig());
    },
    getClaimableBadges(eventId, raceId) {
        return services.http.get(`/v1.0/activity/events/${eventId}/${raceId}/claimable-badges`, this.getConfig());
    },
    claimBadge(eventId, raceId, request) {
        return services.http.post(`/v1.0/activity/events/${eventId}/${raceId}/claimable-badges/claim`, request, this.getConfig());
    },
    connect(id, connectEventIds) {
        return services.http.post(`/v1.0/activity/${id}/connect`, { event_ids: connectEventIds }, this.getConfig());
    },
    delete(id, skipReprocess) {
        return services.http.delete(`/v1.0/activity/${id}?skipReprocess=${skipReprocess||false}`, this.getConfig());
    },
    getTilescores(id, type) {
        return services.http.get(`/v1.0/activity/${id}/tilescores?activityType=${type}`, this.getConfig());
    },
    upload(model) {
        return services.http.post("/v1.0/activity/upload", model, this.getConfig());
    },
    validate(model) {
        return services.http.post("/v1.0/activity/validate", model, this.getConfig());
    },
    manualEntry(model, ignoreValidation, limitToEventId) {
        return services.http.post(`/v1.0/activity/manualentry?ignoreValidation=${ignoreValidation}&limitToEventId=${limitToEventId||''}`, model, this.getConfig());
    },
    edit(id, model) {
        return services.http.put(`/v1.0/activity/${id}`, model, this.getConfig());
    },
    calculateSteps(activity, algo) {
        return services.http.post(`/v1.0/activity/calculate-steps?algo=${algo||''}`, activity, this.getConfig());
    },

    // non rest methods
    zeroDistanceActivities(activity) {
        return ['YOGA', 'MIND_BODY', 'PILATES', 'WEIGHT_LIFTING', 'INDOOR_CARDIO', 'VOLUNTEERING', 'STAIR_CLIMBING', 'DAILY_SUMMARY', 'TENNIS', 'TEAM_SPORTS', 'GOLF', 'CUSTOM', 'OTHER'].some(x => x === activity.type)
        //return activity.type === 'OTHER' || activity.type === 'YOGA' || activity.type === 'YOGA' || activity.type === 'PILATES' || activity.type === 'WEIGHT_LIFTING' || activity.type === 'INDOOR_CARDIO' || activity.type === 'VOLUNTEERING' || activity.type === 'STAIR_CLIMBING' || activity.type === 'DAILY_SUMMARY';
    },
    requireDistance(activity) {
        return !(activity.type === 'INDOOR_CYCLING' || activity.type === 'INDOOR_RUNNING' || this.zeroDistanceActivities(activity));
    },
    validateDistance(activity) {
        return (activity.dist > 0 || !this.requireDistance(activity)) || "Please enter a positive number";
    },
    validateSteps(activity) {
        return (activity.steps > 0 || activity.type !== 'DAILY_SUMMARY') || "Please enter a positive number";
    },

}